import React from 'react';
import './not-found-section.scss';
import { Button } from '../Button/Button';
import { CircleDecoration } from '../CircleDecoration/CircleDecoration';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import FadeDown from '../FadeDown/FadeDown';

export const NotFoundSection = ({ title, description, image }) => {

    const parsedImage = getImage(image);

    return (

        <section className="not-found-section">
            <div className="container">
                <div className="gr-12">
                    <div className="not-found-section__wrap">
                        <FadeDown>
                            <GatsbyImage
                                image={parsedImage}
                                className="rspimg image"
                                alt={description}
                            />
                        </FadeDown>
                        <FadeDown>
                            <p className="title">
                                {title}
                            </p>
                        </FadeDown>
                        <FadeDown>
                            <p className="description">
                                {description}
                            </p>
                        </FadeDown>
                        <FadeDown>
                            <Button
                                link="/"
                                special
                                reverse
                            >
                                Back to home
                            </Button>
                        </FadeDown>
                    </div>
                </div>
            </div>
            <CircleDecoration
                variant="mandy"
                position="not_found"
            />
        </section>
    );
};
