import React, { Fragment } from 'react';
import Layout from '../components/layout/layout'
import { NotFoundSection } from "../components/NotFoundSection/NotFoundSection"
import { graphql, useStaticQuery } from "gatsby";

const IMAGE_QUERY = graphql`
{
  notFoundImage: file(relativePath: {eq: "404.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
}
`

// markup
const NotFoundPage = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <Layout
      pageTitle="Page Not Found"
      description="We could not find the page you were looking for"
      hideCtaSection
      notFound
    >
      <NotFoundSection
        title={<Fragment><span>Oooops!</span> Something went wrong!</Fragment>}
        description={<Fragment>The page you are looking for may have been removed or its name changed.<br></br>Make sure you entered the site name correctly in the address field of your web browser.</Fragment>}
        image={tempImage.notFoundImage}
      />


    </Layout >

  )
}

export default NotFoundPage
